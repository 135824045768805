@font-face {
  font-family: 'ChalkboardSE';
  src: url(./assets/fonts/ChalkboardSE/ChalkboardSE-Light.woff2);
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'ChalkboardSE';
  src: url(./assets/fonts/ChalkboardSE/ChalkboardSE-Regular.woff2);
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ChalkboardSE';
  src: url(./assets/fonts/ChalkboardSE/ChalkboardSE-Bold.woff2);
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

:root {
  --blue: #ABEAFF;
  --yellow: #FEC400;
  --body_bg: var(--blue);
  --skip_color: #fff;
  --navy: #0072AB;

  --main_container_width: 100%;
  --main_container_bg: none;
}

body {
  background: var(--body_bg);
  font-family: 'ChalkboardSE', sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  height: 100vh;

  overflow: hidden;
}

.section {
  --section_bg: none;

  width: var(--main_container_width);
  max-width: 480px;

  position: relative;

  flex-grow: 1;

  display: flex;
  flex-direction: column;

  background: var(--main_container_bg);
  background-size: auto 100%;
}

.button {
  --bg_image: url(./assets/images/button.svg);

  width: 192px;
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;

  text-decoration: none;

  font-size: 20px;
  font-weight: 400;
  line-height: 1.25;

  color: #fff;

  padding: 16px;
  background: var(--bg_image) no-repeat;
  background-size: 100% 100%;
}

.purple_button {
  --bg_image: url(./assets/images/purple_btn.svg);
  width: 192px;
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;

  text-decoration: none;

  font-size: 20px;
  font-weight: 400;
  line-height: 1.25;

  color: #fff;

  padding: 16px;
  background: var(--bg_image) no-repeat;
  background-size: 100% 100%;
}

.purple_button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button:active {
  --bg_image: url(./assets/images/button_active.svg);
}

.button.cancel {
  --bg_image: url(./assets/images/button_cancel.svg);
}

.button.cancel:active {
  --bg_image: url(./assets/images/button_cancel_active.svg);
}

.button.blue {
  --bg_image: url(./assets/images/button_blue.svg);
}

.button.blue:active {
  --bg_image: url(./assets/images/button_blue.svg);
}

.button .text {
  line-height: 1;
}

.skip {
  font-size: 18px;
  line-height: 1.25;
  color: var(--skip_color);
  text-transform: capitalize;
  text-decoration: none;
  display: inline-flex;
  padding: 4px 2px;
  border-bottom: 2px solid currentColor;
  margin: 8px auto 0;
}

.title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.25;
}

[id="root"] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


@media screen and (orientation: portrait) {
  .container {
    max-height: 100%;
  }
}

@media screen and (orientation: landscape) {
  .container {
    transform: rotate(-90deg);
    transform-origin: 50vh 50vh;
    width: 100vh;
    height: 100vw;
  }
}