.tooltip {
    position: relative;
    top: 100px;
  }
  
  .email_notification_text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 54px 20px;
    font-size: 28px;
    text-align: center;
    color: #0072AB;
  }
  
  .emailInput {
    width: 250px;
    height: 40px;
    border-radius: 25px;
    margin-top: 200px;
    font-size: 18px;
    padding: 20px 20px;
    background-color: #A3E3FF;
    color: #0072AB;
    outline: none;
    border-width: 3px 4px 4px 4px;
    border-style: solid;
    border-color: #0072AB;
    
  }
  
  .emailInput::placeholder {
    opacity: 1;
    color: #FFFFFF;
  }

  .image {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 265px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.sub_images_apple {
  position: absolute;
  right: -30px;
  top: -20px;
}

.sub_images_pencil {
  position: absolute;
  left: 60px;
  top: -68px;
}

.sub_images_tractor {
  position: absolute;
  left: -20px;
  top: -40px;
}
.input-container {
  position: relative;
  width: 291px;
  height: 60px;
  margin-top: 200px;
}

.input-svg-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.emailInput {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 40px);
  height: 100%;
  border: none;
  background: transparent;
  font-size: 18px;
  padding-left: 20px;
  color: #0072AB;
  outline: none;
  line-height: 40px;
  margin: 0;
}

.actions {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skip {
  --skip_color: #67cef0;
}

.main_section {
  --main_container_width: 100%;

  position: static;
  background: none;
  padding: 24px 24px 0 24px;
}