.section {
    --main_container_width: 440px;
    --main_container_bg: url(../../../assets/images/home_clouds.svg) no-repeat 50% 50%;
}

.image {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: -120px;

    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.tooltip {
    position: relative;
    top: -16px;
    margin: auto;
}

.text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px 20px 54px 20px;

    font-weight: bold;
    font-size: 28px;
    line-height: 1.08;
    text-align: center;
    color: #0072AB;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-height: 94px;
    margin: 12px 0 20px;

    .button {
        height: 62px;
        width: 185px;
    }
}