.section {
  --main_container_width: 100%;

  position: static;
  background: none;
  padding: 24px 24px 0 24px;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
  }
}

.title {
  text-transform: capitalize;
  color: #0072ab;

  max-width: 300px;
  margin: 0 auto 16px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  flex-grow: 1;
}

.item {
  width: calc(50% - 8px);
  margin: 4px;
  color: #0072ab;

  position: relative;
  display: flex;

  &.active {
    color: white;
    .border {
      fill: #9760eb;
    }

    .bg {
      fill: #d57bff;
    }
  }

  > svg {
    width: 100%;
    height: auto;
  }
}

.image {
  @media (min-height: 600px) {
    div {
      min-height: 60px;
    }
  }
}

.info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.name {
  flex-shrink: 0;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 94px;
  margin: 36px 0 20px;
}

.skip {
  --skip_color: #67cef0;
}
.paw {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 500px;
    z-index: 10;
    pointer-events: none;
}