.section {
    --main_container_width: 100%;

    position: static;
    background: none;
    padding: 24px;
    height: 100%;

    overflow-x: hidden;
    overflow-y: auto;
}

.image {
    height: 176px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0 0 14px;
}

.title {
    text-transform: capitalize;
    color: #026D8C;

    max-width: 280px;
    margin: 0 auto 16px;

    font-size: 18px;
    line-height: 1.25;
}

.form {
    flex-grow: 1;

    display: grid;
    grid-gap: 16px;
    align-content: start;
}

.options {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(2, calc(50% - 6px));
}

.option {
    --old_color: rgba(0, 114, 171, 0.75);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 105px;
    padding: 10px;
    border-radius: 16px;
    position: relative;

    background: #fff;
    box-shadow: 2px 2px 0 0 #0072AB;

    font-weight: 700;
    color: #0072AB;

    &.selected {
        --old_color: #CACACA;

        background: #9660EA;
        box-shadow: inset 0 0 0 1px #FEC400, 0 0 0 2px #FEC400;
        color: #fff
    }
}

.label {
    position: absolute;
    top: -12px;
    left: calc(50% - 30px);

    width: 60px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../../assets/images/label.svg) no-repeat 50% 1px;
    background-size: contain;

    font-size: 12px;
    line-height: 1;
    color: #fff
}

.name {
    font-size: 16px;
    line-height: 20px;
}

.per {
    font-size: 10px;
    line-height: 14px;
    margin: 2px 0 0;

    span {
        text-decoration: line-through;
        color: var(--old_color);
        margin-right: 4px;
    }
}

.price {
    font-size: 18px;
    line-height: 1.25;
    margin: 5px 0 0;
}


.info {
    display: grid;
    grid-gap: 14px;
    justify-content: center;
    justify-items: center;

    padding: 24px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 2px 2px 0 0 #0072AB;

    font-weight: 700;
    color: #0072AB;


    .btn {
        height: 44px;
        font-weight: 700;
        font-size: 11px;
        text-transform: uppercase;
        color: #fff;
        text-shadow: none;

        > div {
            position: relative;
            top: -1px
        }
    }
}

.list {
    display: grid;
    grid-template-rows: repeat(4, minmax(25px, auto));
    grid-gap: 2px;
}

.item {
    display: grid;
    grid-template-columns: 28px 1fr;
    grid-gap: 6px;
    align-items: center;

    font-size: 14px;
    line-height: 18px;
    color: #0072AB;

    &:before {
        content: '';
        display: flex;
        background: url(../../../assets/images/check.svg) no-repeat 50% 50%;
        background-size: contain;

        width: 100%;
        height: 25px;
        position: relative;
        top: 2px;
    }
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 0;
}

.skip {
    --skip_color: #0072AB;
}

.note {
    font-size: 10px;
    line-height: 12px;
    color: #0072AB;
    margin: 12px 0 0;
}