.loaderContainer {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loaderText {
    font-weight: 400;
    font-size: 28px;
    text-align: center;
    color: #fff;
}

.animation {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;

    animation: show 3s forwards ease-out;

    > div {
        min-width: 117%;
    }
    
    @keyframes show {
        0% {
            transform: translateY(-70%);
        }
        100% {
            transform: translateY(0%);
        }
    }
}

.info {
    height: 100%;

    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    --indent: 9%;

    font-weight: 700;
    font-size: 19px;
    line-height: 22px;
    text-align: center;
    color: #fff;

    margin: var(--indent) 0 auto;

    opacity: 0;

    animation: show 1s linear forwards 2.5s;

    @media (min-height: 600px) {
        --indent: 12%;
    }

    @media (min-height: 900px) {
        --indent: 15%;
    }
}

.note {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #9DB1E2;
    margin: 0 0 32px;
}

.actions {
    margin: 0 0 12px;
}

@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}