.section {
  --main_container_width: 375px;
  --main_container_bg: url(../../../assets/images/home_clouds.svg) no-repeat 50%
    50%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.image {
  position: absolute;
  padding-bottom: 200px;
  z-index: 1;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ballImage {
  position: absolute;
  width: 300px;
  height: 100px;
  background: url(../../../assets/images/ball.svg) no-repeat 50% 1px;
  background-size: contain;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  left: 150px;
  top: 150px;
}

.puzzleImage {
  position: absolute;
  width: 300px;
  height: 100px;
  background: url(../../../assets/images/puzzle.svg) no-repeat 50% 1px;
  background-size: contain;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  right: 150px;
  top: 100px;
}

.downloadImage {
  position: relative;
  top: 20px;
  width: 250px;
  height: 250px;
  background: url(../../../assets/images/button_download.svg) no-repeat 50% 1px;
  background-size: contain;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  z-index: 2;
}

.mainContent {
  position: relative;
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mainText {
  text-align: center;
  font-size: 2em;
  text-decoration: solid;
  color: var(--navy);
}

.secondText {
  padding-top: 20px;
  text-align: center;
  font-size: 1em;
  text-decoration: solid;
  color: #fff;
}

.imageContainer {
  height: 50%;
}

.loaderContainer {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loaderText {
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  color: #fff;
}
