.loaderContainer {
    height: 100%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .loaderText {
    font-weight: 400;
    font-size: 28px;
    text-align: center;
    color: #fff;
  }