.loaderContainer {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loaderText {
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  color: #fff;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  --main_container_width: 100%;
}

.main_content {
  height: 70%;
  background-color: white;
  width: 100vh;
}

.image {
  position: fixed;
  padding-bottom: 300px;
  z-index: 1;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
}

.sign_in {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 150px 20px 20px 20px;

  font-weight: bold;
  font-size: 24px;
  line-height: 1.08;
  text-align: center;
  color: var(--navy);
}

.facebook_login {
  width: 300px;
  height: 100px;
  background: url(../../../assets/images/facebook.svg) no-repeat 50% 1px;
  background-size: contain;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}

.note {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #9db1e2;
  margin: 0 0 32px;
  margin-top: auto;
  padding: 0px 50px 0px 50px;
  width: 50%;
}

.anchor {
  color: #9984f6;
}
